@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-color-dark: #4ecdc4;
  --theme-color-light: #ff6b6b;
  --theme-hover-dark: #45b8b0;
  --theme-hover-light: #ff5252;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
